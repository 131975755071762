import React from "react";

import CandidateFormBuilder from "./formBuilder";

import "./styles.scss";

const BoardingPass = ({ event, onSubmit }) => {
  const CandidateForm = CandidateFormBuilder();
  return (
    <div className="boardingPassComponent">
      <header>
        <h1>Boarding Pass</h1>
        <h2>
          <img
            src={require("../../assets/img/boarding-pass/rocket.svg")}
            alt="Rocket"
          />{" "}
          Eleven Labs
        </h2>
      </header>
      <main>
        <section className="block--borderRight">
          <div className="block--borderBottom blockWelcome">
            <p className="blockWelcome__title">
              We wish you
              <br />a pleasant
            </p>
            <p className="blockWelcome__subtitle">{event.name}</p>
          </div>
          <div className="blockExpertise">
            <p className="blockExpertise-title">{event.expertiseBlock.title}</p>
            <ul className="blockExpertise-list">
              {event.expertiseBlock.list.map((value, key) => (
                <li key={key}>{value}</li>
              ))}
            </ul>
          </div>
        </section>
        <section className="block--borderRight">
          <div className="block--borderBottom blockEventInfo">
            <img
              src={require("../../assets/img/boarding-pass/rocket.png")}
              alt=""
            />
            <div>
              <p>
                From: <span>{event.from}</span>
              </p>
              <p>
                To: <span>{event.to}</span>
              </p>
              <p>
                Company: <span>Eleven Labs</span>
              </p>
            </div>
          </div>
          <div>
            <CandidateForm.Formik onSubmit={onSubmit} />
          </div>
        </section>
        <section>
          <div className="block--borderBottom blockEcusson">
            <img
              src={require("../../assets/img/boarding-pass/ecusson.png")}
              alt="First Class"
            />
          </div>
          <div className="blockStandInfo">
            <p
              className="blockStandInfo__title"
              dangerouslySetInnerHTML={{
                __html: event.standBlock.text.replace(/\n/gm, "<br />")
              }}
            />
            <img
              src={require("../../assets/img/boarding-pass/stand-rocket.png")}
              alt=""
            />
            <div>
              <p>
                Siège <span>E11</span>
              </p>
              <p>
                <img
                  src={require("../../assets/img/boarding-pass/picto-seat.png")}
                  alt=""
                />{" "}
                Fenêtre
              </p>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default BoardingPass;
