import React, { Component } from "react";
import { Layout, Menu, Icon } from "antd";

import { renderRoutes } from "react-router-config";
import { Redirect, withRouter } from "react-router";
import { compose } from "recompose";

import { withUser } from "../../../core/auth";

import { paths } from "../../../routes";

import "./styles.scss";

class AdminLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPath:
        props.location.pathname === "/admin/"
          ? paths.DASHBOARD
          : props.location.pathname
    };
  }

  switchRoute = e => {
    const { history } = this.props;
    history.push(e.key);
  };

  onClickHeaderMenu = e => {
    const { history } = this.props;
    // eslint-disable-next-line default-case
    switch (e.key) {
      case "profile":
        history.push(paths.PROFILE);
        break;
      case "logout":
        history.push(paths.LOGOUT);
        break;
    }
  };

  render() {
    const { currentPath } = this.state;
    const { user, route } = this.props;
    
    if (!user) {
      return <Redirect to={paths.LOGIN} />;
    } else if (!user.hasRole("ADMIN")) {
      return <Redirect to={paths.HOME} />;
    }

    return (
      <Layout className="admin-layout">
        <Layout.Sider trigger={null} className="admin-layout__sider">
          <div className="admin-layout__sider-logo">Eleven Labs</div>
          <Menu
            onClick={this.switchRoute}
            theme="dark"
            mode="inline"
            defaultSelectedKeys={[currentPath]}
          >
            <Menu.Item key={paths.DASHBOARD}>
              <Icon type="dashboard" /> Dashboard
            </Menu.Item>
          </Menu>
        </Layout.Sider>
        <Layout className="admin-layout__container">
          <Layout.Header className="admin-layout__header">
            <span />
            <Menu
              className="admin-layout__header-menu"
              mode="inline"
              theme="dark"
              onClick={this.onClickHeaderMenu}
            >
              <Menu.SubMenu
                title={
                  <span>
                    <Icon type="user" />
                    <span>{user.username}</span>
                  </span>
                }
              >
                <Menu.Item key="profile">
                  <Icon type="user" /> Profile
                </Menu.Item>
                <Menu.Item key="logout">
                  <Icon type="logout" /> Logout
                </Menu.Item>
              </Menu.SubMenu>
            </Menu>
          </Layout.Header>
          <Layout.Content className="admin-layout__content">
            {renderRoutes(route.routes)}
          </Layout.Content>
        </Layout>
      </Layout>
    );
  }
}

export default compose(
  withRouter,
  withUser
)(AdminLayout);
