import gql from "graphql-tag";

export const AuthPayload = gql`
  fragment AuthPayload on AuthPayload {
    accessToken
    user {
      id
      email
      username
      roles
    }
  }
`;

export const User = gql`
  fragment User on User {
    id
    username
    email
    roles
  }
`;

export const Event = gql`
  fragment Event on Event {
    slug
		name
    from
    to
    expertiseBlock {
			title
      list
		}
    standBlock {
			text
		}
  }
`;

export const Candidate = gql`
  fragment Candidate on Candidate {
    id
    firstname
    lastname
    email
    score
    event {
      ...Event
    }
  }
  ${Event}
`;
