import React from "react";
import { Spin } from "antd";
import { Query } from "react-apollo";

import { Redirect } from "react-router";

import { queries } from "../graphql";
import { paths } from "../../routes";

export const WithMe = ({ isAuthentificated = true, ...props }) => (
  <Query query={queries.ME} isAuthentificated={isAuthentificated}>
    {({ loading, data = {}, error }) => {
      if (loading) return <Spin />;
      if (isAuthentificated && error) return <Redirect to={paths.LOGIN} />;

      if (data.me) {
        let { user } = data.me;
        data.me.user.hasRole = role => user.roles.includes(role);
      }

      return props.children(data.me || {});
    }}
  </Query>
);

export const withUser = (Component, { isAuthentificated = true } = {}) => props => (
  <WithMe isAuthentificated={isAuthentificated}>
    {me => <Component {...props} user={me.user || null} />}
  </WithMe>
);
