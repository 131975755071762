import FormBuilder from "@elevenlabs/react-formbuilder";

const LoginForm = () => {
  const form = new FormBuilder();

  form
    .createForm()
    .add("email", "TextField", {
      placeholder: "Email",
      type: "email",
      required: true,
      validators: ["Required"]
    })
    .add("password", "TextField", {
      type: "password",
      placeholder: "Password",
      required: true,
      validators: ["Required"]
    })
    .add("submit", "Button", {
      type: "submit",
      label: "Login",
      ghost: true,
      block: true
    });

  return form;
};

export default LoginForm;
