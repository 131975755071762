import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloLink } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";

import { onError } from "apollo-link-error";

import { getAuth, clearAuth } from "../auth";

const createClientGraphQL = () => {
  const cache = new InMemoryCache();

  const authLink = setContext((_, { headers }) => {
    const auth = getAuth();
    return {
      headers: {
        ...headers,
        authorization:
          auth && auth.accessToken ? `Bearer ${auth.accessToken}` : ""
      }
    };
  });

  const httpLink = new HttpLink({
    uri: "https://elevenlabs-boarding-pass-server.now.sh/graphql"
  });

  const errorLink = onError(({ networkError }) => {
    if (networkError) {
      // eslint-disable-next-line default-case
      switch (networkError.statusCode) {
        case 400:
          clearAuth();
          break;
      }
    }
  });

  const client = new ApolloClient({
    connectToDevTools: true,
    cache,
    link: ApolloLink.from([errorLink, authLink, httpLink]),
    name: "ElevenLabs Boarding Pass GraphQL [web]",
    version: "1.0.0"
  });

  return client;
};

export default createClientGraphQL;
