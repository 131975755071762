import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { paths } from "../../../routes";
import { withUser } from "../../../core/auth";
import { ReactComponent as RocketLogo } from "../../../assets/img/rocket-logo.svg";

import "./styles.scss";

class WebsiteLayout extends Component {
  render() {
    const { className, title, subtitle, user } = this.props;
    return (
      <div className={classNames("layout-website", className)}>
        <header className="layout-website__header">
          <div className="layout-website__header-top">
            <Link to={paths.HOME}>
              <RocketLogo /> Eleven Labs
            </Link>
            <nav>
              <Link to={paths.HOME}>Home</Link>
              {user ? (
                <Fragment>
                  {user.hasRole("ADMIN") && <Link to={paths.ADMIN}>Admin</Link>}
                  <Link to={paths.LOGOUT}>Logout</Link>
                </Fragment>
              ) : (
                <Fragment>
                  <Link to={paths.LOGIN}>Login</Link>
                </Fragment>
              )}
            </nav>
          </div>
          <div className="layout-website__header-middle">
            <h1>{title}</h1>
            {subtitle && <p>{subtitle}</p>}
          </div>
        </header>
        {this.props.children}
      </div>
    );
  }
}

export default withUser(WebsiteLayout, {isAuthentificated: false});
