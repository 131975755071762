import gql from "graphql-tag";

import { User, Candidate, Event } from "../fragments";

export const ME = gql`
  query ME {
    me {
      accessToken
      user {
        ...User
      }
    }
  }
  ${User}
`;

export const REGISTERED_ON_BOARDING = gql`
  query REGISTERED_ON_BOARDING {
    registeredOnBoarding {
      ...Candidate
    }
  }
  ${Candidate}
`;

export const EVENTS = gql`
  query EVENTS {
    events {
      ...Event
    }
  }
  ${Event}
`;

export const EVENT = gql`
  query EVENT($slug: String!) {
    event(slug: $slug) {
      ...Event
    }
  }
  ${Event}
`;
