import React from "react";

import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const Wysiwyg = ({ value, onChange }) => (
  <CKEditor
    editor={ClassicEditor}
    data={value}
    config={{
      toolbar: [
        "bold",
        "italic",
        "blockQuote",
        "numberedList",
        "bulletedList",
        "link",
        "undo",
        "redo"
      ]
    }}
    onChange={(event, editor) => {
      const data = editor.getData();
      onChange(data);
    }}
  />
);

export default Wysiwyg;
