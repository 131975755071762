import React from "react";

import "./styles.scss";

const NotFoundScreen = () => (
  <div className="not-found-page">
    <div className="not-found-page__content">
      <p>
        <span className="not-found-page__title">404</span> |{" "}
        <span className="not-found-page__subtitle">
          This page could not be found.
        </span>
      </p>
    </div>
    <div className="not-found-page__background" />
  </div>
);

export default NotFoundScreen;
