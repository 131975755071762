import React from "react";

import HomeScreen from "./screens/Home";
import EventScreen from "./screens/Event";
import NotFoundScreen from "./screens/NotFound";

import { LoginScreen } from "./screens/Auth";
import Logout from "./components/Logout";
import LayoutAdmin from "./components/Layout/Admin";
import { DashboardScreen } from "./screens/Admin";

export const paths = {
  HOME: "/",
  EVENT: "/event/:slug",
  LOGIN: "/login",
  LOGOUT: "/logout",
  ADMIN: "/admin/",
  DASHBOARD: "/admin/dashboard",
  NOT_FOUND: "/404"
};

const routes = [
  {
    path: paths.HOME,
    exact: true,
    component: HomeScreen
  },
  {
    path: paths.LOGIN,
    exact: true,
    component: LoginScreen
  },
  {
    path: paths.LOGOUT,
    exact: true,
    component: Logout
  },
  {
    path: paths.NOT_FOUND,
    exact: true,
    component: NotFoundScreen
  },
  {
    strict: true,
    path: paths.ADMIN,
    component: LayoutAdmin,
    routes: [
      {
        exact: true,
        path: paths.ADMIN,
        component: DashboardScreen
      },
      {
        path: paths.DASHBOARD,
        component: DashboardScreen
      },
      {
        component: () => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "calc(100vh - 48px - 64px)",
              fontSize: "1.5rem"
            }}
          >
            404 | This page could not be found.
          </div>
        )
      }
    ]
  },
  {
    path: paths.EVENT,
    component: EventScreen
  }
];

export default routes;
