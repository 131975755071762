import React, { Component } from "react";
import { Redirect } from "react-router";
import { Query, Mutation } from "react-apollo";

import { Spin, message } from "antd";

import { paths } from "../../routes";
import { queries, mutations } from "../../core/graphql";

import "./styles.scss";
import BoardingPass from "../../components/BoardingPass";

class EventScreen extends Component {
  onSubmit = registerOnBoarding => payload => {
    const {
      match: { params }
    } = this.props;
    return registerOnBoarding({ variables: { input: {...payload, eventSlug: params.slug } } });
  }

  render() {
    const {
      match: { params }
    } = this.props;
    return (
      <Query query={queries.EVENT} variables={{ slug: params.slug }}>
        {({ loading, data, error }) => {
          if (loading) return <Spin />;
          if (!data.event) return <Redirect to={paths.NOT_FOUND} />;

          return (
            <Mutation
              mutation={mutations.REGISTER_ON_BOARDING}
              onCompleted={({ registeredOnBoarding }) => {
                message.success(`${registeredOnBoarding.firstname} ${registeredOnBoarding.lastname} bien enregistré à l'embarquement`);
              }}
              onError={({ graphQLErrors }) => {
                for (const key in graphQLErrors) {
                  const graphQLError = graphQLErrors[key];
                  message.error(graphQLError.message);
                }
              }}
            >
              {(registerOnBoarding, { loading }) => {
                if (loading) return <Spin />;

                return (
                  <BoardingPass
                    event={data.event}
                    onSubmit={this.onSubmit(registerOnBoarding)}
                  />
                );
              }}
            </Mutation>
          );
        }}
      </Query>
    );
  }
}

export default EventScreen;
