export const setAuth = ({ accessToken, refreshToken }) => {
  sessionStorage.setItem(
    "auth",
    JSON.stringify({
      accessToken,
      refreshToken
    })
  );
};

export const getAuth = () => {
  const auth = sessionStorage.getItem("auth");
  try {
    return JSON.parse(auth);
  } catch (e) {
    return false;
  }
};

export const clearAuth = () => {
  sessionStorage.removeItem("auth");
};

export { WithMe, withUser } from "./withMe";
