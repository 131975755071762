import React, { Component } from "react";
import { Query } from "react-apollo";

import { Table, Spin } from "antd";

import { queries } from "../../../core/graphql";

class DashboardScreen extends Component {
  get columns() {
    return [
      {
        title: "Nom",
        dataIndex: "lastname"
      },
      {
        title: "Prénom",
        dataIndex: "firstname"
      },
      {
        title: "Email",
        dataIndex: "email"
      },
      {
        title: "Score",
        dataIndex: "score"
      },
      {
        title: "Event",
        dataIndex: "event.name"
      }
    ];
  }

  render() {
    return (
      <Query query={queries.REGISTERED_ON_BOARDING}>
        {({ loading, data, error }) => {
          if (loading) return <Spin />;
          return <Table dataSource={data.registeredOnBoarding} columns={this.columns} />;
        }}
      </Query>
    );
  }
}

export default DashboardScreen;
