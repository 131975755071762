import React, { Component } from "react";
import { Query } from "react-apollo";

import { Card, Col, Row, Spin } from "antd";

import { queries } from "../../core/graphql";
import WebsiteLayout from "../../components/Layout/Website";

import "./styles.scss";
import { paths } from "../../routes";

class HomeScreen extends Component {
  goToEvent = slug => () => {
    const { history } = this.props;
    history.push(paths.EVENT.replace(':slug', slug));
  };

  render() {
    return (
      <WebsiteLayout title={"Events"}>
        <div className="post-list">
          <Row gutter={64}>
            <Query query={queries.EVENTS}>
              {({ loading, data, error }) => {
                if (loading) return <Spin />;

                return data.events.map((event, key) => (
                  <Col span={8} key={key}>
                    <Card
                      onClick={this.goToEvent(event.slug)}
                      cover={
                        <img
                          alt="example"
                          src="https://images.unsplash.com/photo-1469433791803-c2719135f970?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"
                        />
                      }
                    >
                      <h1>{event.name}</h1>
                    </Card>
                  </Col>
                ));
              }}
            </Query>
          </Row>
        </div>
      </WebsiteLayout>
    );
  }
}

export default HomeScreen;
