import gql from "graphql-tag";

import { AuthPayload, Candidate } from "../fragments";

export const LOGIN = gql`
  mutation LOGIN($email: String!, $password: String!) {
    authPayload: login(email: $email, password: $password) {
      ...AuthPayload
    }
  }
  ${AuthPayload}
`;

export const REGISTER_ON_BOARDING = gql`
  mutation REGISTER_ON_BOARDING($input: CandidateInput!) {
    registeredOnBoarding: registerOnBoarding(input: $input) {
      ...Candidate
    }
  }
  ${Candidate}
`;
