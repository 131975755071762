const translate = (key, args) => {
  const translations = {
    "form.validators.required": ({ label }) =>
      `Please input your ${label.toLowerCase()}!`,
    "form.validators.email": () => `Email is invalid!`
  };

  return translations[key] ? translations[key](args) : key;
};

export default translate;
