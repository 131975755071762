import React from "react";
import { Redirect } from "react-router";
import { ApolloConsumer } from "react-apollo";

import { paths } from "../../routes";
import { clearAuth } from "../../core/auth";

const Logout = () => (
  <ApolloConsumer>
    {client => {
      clearAuth();
      client.resetStore();
      return <Redirect to={paths.HOME} />;
    }}
  </ApolloConsumer>
);

export default Logout;
