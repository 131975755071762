import React from "react";
import { pick } from "lodash";

import { Form, Input, Select, Button } from "antd";

import Wysiwyg from "../../components/Wysiwyg";

const theme = {
  Form: ({ children, onSubmit, className }) => (
    <form onSubmit={onSubmit} className={className}>
      {children}
    </form>
  ),
  Row: ({ label, Field, fieldType, errors, required, ...props }) => {
    if (["Button"].includes(fieldType)) {
      return <Field />;
    }

    let allowedPropsFormItem = { label, required };
    if (errors && errors.length > 0) {
      allowedPropsFormItem.hasFeedback = true;
      allowedPropsFormItem.validateStatus = "error";
      allowedPropsFormItem.help = errors;
    }

    return (
      <Form.Item {...allowedPropsFormItem}>
        <Field />
      </Form.Item>
    );
  },
  Label: ({ label }) => (
    <div className="ant-form-item-label">
      <label>{label}</label>
    </div>
  ),
  Errors: ({ errors }) => <div className="ant-form-explain">{errors}</div>,
  TextField: ({ dirty, touched, required, ...props }) => (
    <Input {...props} dirty={dirty.toString()} touched={touched.toString()} />
  ),
  TextareaField: ({ dirty, touched, required, ...props }) => (
    <Input.TextArea
      {...props}
      dirty={dirty.toString()}
      touched={touched.toString()}
    />
  ),
  SelectField: ({ formik, choices = [], isMultiple = false, ...props }) => {
    let defaultValue = isMultiple ? [] : "";
    props.value = props.value.length === 0 ? defaultValue : props.value;
    const handleChange = value => {
      formik.setFieldValue(props.name, value ? value : defaultValue);
    };
    let allowedPropsSelect = pick(props, ["placeholder", "value"]);
    return (
      <Select
        {...allowedPropsSelect}
        onChange={handleChange}
        mode={isMultiple ? "multiple" : "default"}
      >
        {choices.map((choice, key) => (
          <Select.Option key={key} value={choice.value}>
            {choice.label}
          </Select.Option>
        ))}
      </Select>
    );
  },

  WysiwygField: ({ formik, ...props }) => {
    const handleChange = value => {
      formik.setFieldValue(props.name, value);
    };
    let allowedPropsWysiwyg = pick(props, ["value"]);
    return <Wysiwyg onChange={handleChange} {...allowedPropsWysiwyg} />;
  },
  Button: ({ label, type, theme, ...props }) => {
    let allowedPropsButton = pick(props, ["block", "ghost"]);
    return (
      <Button htmlType={type} type={theme} {...allowedPropsButton}>
        {label}
      </Button>
    );
  }
};

export default theme;
