import React, { Component } from "react";

import { message, Spin } from "antd";

import { ReactComponent as RocketLogo } from "../../../assets/img/rocket-logo.svg";

import { Mutation, ApolloConsumer } from "react-apollo";

import { setAuth } from "../../../core/auth";
import { paths } from "../../../routes";
import { mutations, queries } from "../../../core/graphql";

import LoginFormBuilder from "./form";

import "../styles.scss";

class LoginScreen extends Component {
  state = {
    LoginForm: LoginFormBuilder(),
    initialValues: {
      email: "wilson@eleven-labs.com",
      password: "wilson"
    },
    payload: null
  };

  onSubmit = login => payload => login({ variables: payload });

  render() {
    const { LoginForm, initialValues } = this.state;

    return (
      <div className="screen-auth">
        <div className="screen-auth__container">
          <span className="screen-auth__logo">
            <RocketLogo /> Eleven Labs
          </span>
              <Mutation
                mutation={mutations.LOGIN}
                refetchQueries={[{ query: queries.ME }]}
                awaitRefetchQueries={true}
                update={(cache, { data: { authPayload } }) => {
                  const { accessToken, refreshToken } = authPayload;
                  setAuth({ accessToken, refreshToken });
                }}
                onCompleted={({ authPayload: { user } }) => {
                  const { history } = this.props;
                  history.push(
                    user.roles.includes("ADMIN") ? paths.ADMIN : paths.HOME
                  );
                }}
                onError={({ graphQLErrors }) => {
                  for (const key in graphQLErrors) {
                    const graphQLError = graphQLErrors[key];
                    message.error(graphQLError.message);
                  }
                }}
              >
                {(login, { loading }) => {
                  if (loading) return <Spin />;

                  return (
                    <LoginForm.Formik
                      className="screen-auth__form"
                      onSubmit={this.onSubmit(login)}
                      initialValues={initialValues}
                    />
                  );
                }}
              </Mutation>
        </div>
        <div className="screen-auth__background" />
      </div>
    );
  }
}

export default LoginScreen;
