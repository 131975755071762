import React from "react";

import { BrowserRouter } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import { ApolloProvider } from "react-apollo";

import routes from "../../routes";
import createClientGraphQL from "../../core/graphql/client";

const client = createClientGraphQL();

const RootBrowser = () => (
  <ApolloProvider client={client}>
    <BrowserRouter>{renderRoutes(routes)}</BrowserRouter>
  </ApolloProvider>
);

export default RootBrowser;
