import FormBuilder from "@elevenlabs/react-formbuilder";
import minimalTheme from "../../core/formBuilder/themeMinimal";

const CandidateForm = () => {
  const form = new FormBuilder();
  form.setTheme(minimalTheme);

  form
    .createForm()
    .add("firstname", "TextField", {
      label: "Prénom",
      required: true,
      validators: ["Required"]
    })
    .add("lastname", "TextField", {
      label: "Nom",
      required: true,
      validators: ["Required"]
    })
    .add("email", "TextField", {
      label: "Email",
      type: "email"
    })
    .add("score", "TextField", {
      label: "Score",
      type: "score"
    })
    .add("submit", "Button", {
      type: "submit",
      label: "Valider"
    });

  return form;
};

export default CandidateForm;
